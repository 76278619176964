import React, { useEffect, useContext } from "react";
import PopularCategories from "../components/PageBlocks/Home/PopularCategories/PopularCategories";
import SliderBlock from "../components/PageBlocks/Home/SliderBlock/SliderBlock";
import VisitedProduct from "../components/PageBlocks/Home/VisitedProduct/VisitedProduct";
import ProductBrands from "../components/PageBlocks/Home/ProductBrands/ProductBrands";
import ProductReview from "../components/PageBlocks/Home/ProductReview/ProductReview";
import SeoTextMainPage from "../components/PageBlocks/SeoTextMainPage/SeoTextMainPage";
import { API_BASE_URL } from "../context/appState";
import Head from "next/head";
import SliderTypeHomeBlock from "../components/PageBlocks/Home/SliderTypeHomeBlock/SliderTypeHomeBlock";
import { AppContext } from "../context/appState";
import { i18n } from "../i18n";
import ProductsWithTitle from "../components/PageBlocks/ProductPage/ProductsWithTitle";

const Home = props => {
    const { setLoader, dispatch } = useContext(AppContext);
    const { language } = i18n;

    useEffect(() => {
        setLoader(true, dispatch);
    }, []);

    return (
        <div className="home_page">
            <Head>
                <script type="application/ld+json">{JSON.stringify(props.schema)}</script>
            </Head>
            <SliderBlock {...{ jsonMainpage: props.mainpage, ...props }} />
            <PopularCategories {...props} />
            <ProductsWithTitle list={props.newProducts} title={'Новинки'} />
            <ProductsWithTitle list={props.customBlock.products} title={language === 'ua' ? props.customBlock.title_ua : props.customBlock.title} WithoutT />
            {!!props.topSales.count && <SliderTypeHomeBlock {...{ initialProducts: props.topSales, title: 'Топ продаж', link: 'top_sales' }} />}
            <SeoTextMainPage {...{ jsonMainpage: props.mainpage, ...props }} />
            <VisitedProduct />
            <ProductBrands {...props} />
            <ProductReview {...props} />
        </div>
    );
};

Home.getInitialProps = async function (context) {
    const [
        jsonNew,
        jsonCats,
        jsonBrand,
        jsonReview,
        respSlider,
        jsonTopSales,
        customBlock,
        jsonMainpage,
    ] = await Promise.all([
        fetch(`${API_BASE_URL}/products/new/?page_size=20`).then(res => res.json()),
        fetch(`${API_BASE_URL}/category/popularity/?page_size=20`).then(res => res.json()),
        fetch(`${API_BASE_URL}/brand/`).then(res => res.json()),
        fetch(`${API_BASE_URL}/reviews/display-on-main/`).then(res => res.json()),
        fetch(`${API_BASE_URL}/slider/`).then(res => res.json()),
        fetch(`${API_BASE_URL}/products/top_sales/?page_size=10`).then(res => res.json()),
        fetch(`${API_BASE_URL}/products/block/`).then(res => res.json()),
        fetch(`${API_BASE_URL}/mainpage/`).then(res => res.json())
    ]);
    const isUA = context?.req?.i18n?.language && context?.req?.i18n?.language === "ua";

    let schema = {
        "@context": "https://schema.org",
        "@type": "Organization",
        address: {
            "@type": "PostalAddress",
            addressLocality: "Украина, Полтава",
            postalCode: "36000",
            streetAddress: "проспект Мира 36"
        },
        email: "poltava.prorab@gmail.com",
        name: "PRORAB: Маркет для дома",
        telephone: "0 800 33 07 57"
    };
    return {
        newProducts: jsonNew,
        mainpage: jsonMainpage,
        popularCategories: jsonCats,
        ourBrands: jsonBrand,
        productsReview: jsonReview,
        sliderImages: respSlider,
        topSales: jsonTopSales,

        title: isUA
            ? " ᐉ Будівельний магазин ⭐ інтернет магазин будматеріалів Прораб"
            : " ᐉ Строительный магазин ⭐ интернет магазин стройматериалов Прораб",
        description: isUA
            ? "Магазин Прораб ✔️ Безкоштовна доставка будівельних матеріалів в Полтаві  ☛ Телефонуйте ☎️ [099] 773-04-44"
            : "Магазин Прораб ✔️ Бесплатная доставка строительных материалов в Полтаве  ☛ Звоните ☎️ [099] 773-04-44",
        keywords: isUA
            ? "Будівельний магазин, інтернет магазин будматеріалів Прораб"
            : "Строительный магазин, интернет магазин стройматериалов Прораб",
        pageClasses: "relative_wrapper",
        schema: schema,
        namespacesRequired: ["home", "product"],
        customBlock,
        isUA
    };
};

export default Home;
